import {
   Text
} from '@fluentui/react-components'
import {
   Link
} from 'react-router-dom'
import config from '@config/server.json'
import formatRupiah from '@utils/global/formatRupiah'


export default function ProductItems({products}) {
   return (
      <div className="product_items">
         {products.map((product, i) => (
            <Link className="product" key={i} to={`/merch/${product.id}`}>
               <img src={`${config.api.base}/img/${product.images[0]}`}
                  alt={product.name}
                  title={product.name}
                  className='product_image'
                  loading='lazy'
               />
               
               <div className="content">
                  <Text className='title'>{product.name}</Text>
                  <div className="prices">
                     
                     <div className="price"><div className="currency">Rp</div>{formatRupiah(product.minPrice).replace('Rp', '').trim()}</div>
                     {product.minPrice !== product.maxPrice && (
                        <>
                           <div className="separator" />
                           <div className="price">
                              <div className="currency">Rp</div>
                              {formatRupiah(product.maxPrice).replace('Rp', '').trim()}
                           </div>
                        </>
                     )}
                  </div>
                  <div className="sold">
                     {product.order_count} Terjual
                  </div>
               </div>
            </Link>
         ))}
      </div>
   )
}