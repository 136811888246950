import './style.scss'
import Heading from '@component/Heading'
import MainFooter from '@component/MainFooter'
import privacyPolicyIlustration from '@static/ilustrations/privacy-policy.png'
import {Text} from '@fluentui/react-components'
import Docs from './Docs'

export default function PrivacyPolicy() {
    return (
        <div className="privacy-policy" page='privacy-policy'>
            <Heading mainImage={privacyPolicyIlustration} leftComponent={(
                <div className='pp-heading'>
                    <Text className='title'>Kebijakan Privasi</Text>
                </div>
            )} />
            <Docs />
            <MainFooter />
        </div>
    )
}