import { useEffect, useState } from 'react'
import {
   Dialog,
   DialogActions,
   DialogSurface,
   DialogTitle,
   DialogBody,
   DialogContent,
   Button,
   Input,
   Field,
   Textarea,
   RadioGroup,
   Radio,
   Label
} from '@fluentui/react-components'
import formatRupiah from '@utils/global/formatRupiah'
import {Alert} from '@fluentui/react-components/unstable'

export default function ConfirmationDialog({ open, onClose, onBuyerName, onBuyerContact, onNote, onConfirm, confirmLoading, amount, onPayment }) {

   const [buyerName, setBuyerName] = useState('')
   const [buyerContact, setBuyerContact] = useState('')
   const [note, setNote] = useState('')
   const [payment, setPayment] = useState('')

   useEffect(() => {
      onPayment && onPayment(payment)
   }, [payment])

   const [confirmDisabled, setConfirmDisabled] = useState(false)

   useEffect(() => {
      setConfirmDisabled(
         buyerName.trim().length < 1 ||
         buyerContact.trim().length < 1
      )
   }, [buyerName, buyerContact])

   useEffect(() => {
      onBuyerName(buyerName)
   }, [buyerName])

   useEffect(() => {
      onBuyerContact(buyerContact)
   }, [buyerContact])

   useEffect(() => {
      onNote(note)
   }, [note])


   const handleChangeBuyerName = e => setBuyerName(e.target.value)
   const handleChangeBuyerContact = e => setBuyerContact(e.target.value)
   const handleChangeNote = e => setNote(e.target.value)

   return (
      <Dialog open={open} onOpenChange={onClose}>
         <DialogSurface>
            <DialogBody>
               <DialogTitle>Konfirmasi Pembelian</DialogTitle>
               <DialogContent>
                  <div className="confirmation_form">
                     <div className="head">
                        <Input 
                           placeholder='Nama Pembeli' 
                           appearance='filled-lighter-shadow'
                           value={buyerName}
                           onChange={handleChangeBuyerName} />
                        <Input 
                           placeholder='Kontak Pembeli' 
                           appearance='filled-lighter-shadow'
                           value={buyerContact}
                           onChange={handleChangeBuyerContact} />
                     </div>
                  </div>
                  <Field label="Catatan" style={{marginTop: 10}}>
                     <Textarea placeholder='Catatan' 
                        appearance='filled-lighter-shadow'
                        resize='vertical'
                        style={{
                           height: 150
                        }}
                        value={note}
                        onChange={handleChangeNote}
                     />
                  </Field>
                  <Bank onBank={bank => setPayment(bank)} />
                  <Alert intent='warning' style={{
                     padding: '4px 10px',
                     borderRadius: 10,
                     marginTop: 10
                  }}>
                     Anda akan di arah kan ke pesan WA untuk mengirimkan bukti pembayaran
                  </Alert>
               </DialogContent>
               <DialogActions>
                  <Button
                     onClick={onClose}
                     appearance='outline'
                  >Batal</Button>
                  <Button
                     appearance='primary'
                     onClick={onConfirm}
                     disabled={confirmDisabled || confirmLoading}
                  >Proses pesanan</Button>
               </DialogActions>
            </DialogBody>
         </DialogSurface>
      </Dialog>
   )
}


function Bank({onBank}) {
   const banks = [
      'BCA - 4480389927 a.n Efendi',
      'Mandiri - 1440016945088 a.n EFENDI'
   ]

   const [value, setValue] = useState(banks[0])

   useEffect(() => {
      onBank && onBank(value)
   }, [value])

   return (
      <div className="bank_checkout_section">
         <Label id='bank_id'>Media Pembayaran</Label>
         <RadioGroup value={value} onChange={e => setValue(e.target.value)}>
            {banks.map((bank, index) => (
               <Radio aria-labelledby='bank_id' label={bank} value={bank} key={index} />
            ))}
         </RadioGroup>
      </div>
   )
}