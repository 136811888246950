import {
    Text
} from '@fluentui/react-components'

export default function HeadOfSection() {
    return (
        <div className="head-of-section">
            <Text className='title'>
                Produk yang kami jual
            </Text>
            <div className='descriptions'>
                <Text className='d'>destinasi online eksklusif Anda untuk merchandise berkualitas tinggi yang dirancang dengan cinta untuk penggemar sejati.</Text>
            </div>
        </div>
    )
}