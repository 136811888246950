import {
    DataBarHorizontal24Filled,
    ArrowMinimize24Filled
} from '@fluentui/react-icons'
import { useEffect, useState, useRef } from 'react'
import config from '@config/headerNavigation.json'
import {
    Link,
    useLocation
} from 'react-router-dom'

export default function MobileMode() {
    const [opened, setOpened] = useState(false)
    const containerRef = useRef()
    const location = useLocation()

    return (
        <div className="mobile-mode">
            <DataBarHorizontal24Filled className='menu-toggle'
                onClick={() => setOpened(x => !x)}
            />
            <div className={`container${opened ? ' open' : ''}`} ref={containerRef}
                style={{
                    top: opened ? '0' : `-${containerRef.current ? containerRef.current.offsetHeight : 0}px`
                }}
            >
                <button id='close-btn' onClick={() => setOpened(false)}>
                    <ArrowMinimize24Filled className='icon' />
                </button>
                {opened && (
                    <div className="menus">
                        {config.pages.map((menu, index) => (
                            <Link 
                            to={menu.path}
                            target={menu.path.startsWith('http') ? '_blank' : ''}
                            onClick={() => setOpened(false)}
                            className={`menu ${
                                location.pathname+location.hash.split('?')[0] === menu.path 
                                ? 'active' 
                                : ''
                            }`} key={index}>
                                <div className="name">{menu.name}</div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}