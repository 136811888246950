import effandAvatar from '@static/teams/effand.jpg'
import fikoAvatar from '@static/teams/fiko.jpg'

import emailIcon from '@static/socials/email.png'
import facebookIcon from '@static/socials/facebook.png'
import telegramIcon from '@static/socials/telegram.png'
import linkedinIcon from '@static/socials/linkedin.png'
import siteIcon from '@static/socials/site.png'

export default {
    teams: [
        {
            name: "Effand",
            position: "Owner",
            image: effandAvatar,
            socials: [
                {
                    name: "effands@gmail.com",
                    url: "mailto:effands@gmail.com",
                    icon: emailIcon,
                    social_name: 'Email'
                },
                {
                    name: "@effands",
                    url: "https://t.me/effands",
                    icon: telegramIcon,
                    social_name: "Telegram"
                },
                {
                    name: "effands nozh",
                    url: "https://www.linkedin.com/in/effands-nozh-60215b27b/",
                    icon: linkedinIcon ,
                    social_name: "Linkedin"
                }
            ]
        },
        {
            name: "Wiji Fiko Teren",
            position: "Software Developer",
            image: fikoAvatar,
            socials: [
                {
                    name: "Fiko Tobel",
                    url: "https://facebook.com/fiko.tobel",
                    icon: facebookIcon,
                    social_name: "Facebook"
                },
                {
                    name: "Wiji Fiko Teren",
                    url: "https://www.linkedin.com/in/wiji-fiko-teren-4a7a00219/",
                    icon: linkedinIcon,
                    social_name: "Linkedin"
                },
                {
                    name: "tobellord@gmail.com",
                    url: "mailto:tobellord@gmail.com",
                    icon: emailIcon,
                    social_name: "Email"
                },
                {
                    name: "Site",
                    url: "https://resume.tobelsoft.my.id",
                    icon: siteIcon,
                    social_name: "Personal Site"
                }
            ]
        }
    ]
}