import config from 'config'
import axios from 'axios'

export default function getMember() {
    return new Promise((resolve, reject) => {
        const url = `${config.api_host}/getMembers`
        axios.get(url)
        .then(({data}) => {
            if(data.error) {
                reject(data.reason)
            } else {
                resolve(data)
            }
        })
        .catch(err => reject(err instanceof Error ? err.message : err))
    })
}