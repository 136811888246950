export default function Doc() {
    return (
        <div className="doc">
            <div className="bold">
                Penggunaan Perangkat Lunak Ziqva Labs
            </div>
            <p>
                Sebagai bagian dari Layanan, kami menyediakan perangkat lunak yang bisa didownload untuk Anda gunakan
                sehubungan dengan Layanan. Ziqva Labs berhak mencabut lisensi secara sepihak tanpa pemberitahuan terlebih dahulu apabila akun anda terindikasi
                melakukan aktifitas yang dianggap merugikan Ziqva Labs. Anda memiliki hak untuk melakukan banding apabila terjadi kesalahan dari pihak Ziqva Labs.
                Selama Anda mematuhi ketentuan ini, kami memberikan Anda akses tidak terbatas.
                Peranan antara Ziqva Labs dan Anda, Ziqva Labs memiliki semua hak, kepemilikan dan kepentingan dalam Perangkat Lunak.
            </p>

            <div className="bold">
                Kewajiban Anda
            </div>
            <p>
                <ul>
                    <li>
                        Anda harus memberikan informasi yang akurat saat membuat akun Ziqva Labs Anda.
                    </li>
                    <li>
                        Anda bertanggung jawab untuk menjaga password dan untuk semua aktivitas yang terjadi dalam akun Anda.
                    </li>
                    <li>
                        Anda harus mematuhi kebijakan penggunaan kami setiap saat ketika menggunakan layanan.
                    </li>
                    <li>
                        Anda tidak pernah menggunakan akun lain tanpa izin.
                    </li>
                    <li>
                        Anda tidak membongkar, mendekompilasi atau merekayasa Ziqva Labs atau berusaha atau membantu orang lain untuk melakukannya, kecuali hal tersebut bertentangan dengan hukum.
                    </li>
                </ul>
            </p>

            <div className="bold">Transfer Data</div>
            <p>
                Sehubungan dengan layanan Sinkronisasi Collections, Ziqva Labs dapat mentransfer, menyimpan dan memproses konten Collections anda di Indonesia atau di negara lain dimana anda
                terkoneksi dengan layanan internet. Dengan menggunakan layanan Sinkronsisasi Collections anda setuju untuk bahwa Ziqva Labs melakukan transfer data Collections, pengolahan dan penyimpanan Konten Anda.
            </p>
            <p>
                Data sensitif pribadi seperti data akun pada layanan AsistenQ Owner tersimpan secara offline dan terenkripsi pada perangkat pengguna, tidak tersimpan pada server.
            </p>

            <div className="bold">
                Pemberitahuan
            </div>
            <p>
                Kami dapat memberikan pengetahuan terkait melalui alamat email yang terkait dengan akun anda dan kami dapat menghubungi anda dengan media pemasaran lainnya melalui informasi yang terkait dengan bisnis,
                termasuk informasi tentang update Service dan update software Ziqva Labs, melalui email.
            </p>

            <div className="bold">
                Modifikasi Layanan
            </div>
            <p>
                Layanan dapat dimodifikasi dari waktu ke waktu, sering tanpa pemberitahuan sebelumnya kepada Anda. jika Anda terus menggunakan Layanan kami. maka Anda memiliki hak untuk
                memberikan masukan atas perubahan layanan kami. Namun menjadi wewenang kami untuk melakukan implementasi pada modifikasi layanan tersebut atau tidak. Jika anda tidak puas dengan modifikasi yang
                kita buat ke layanan, satu satunya solusi adalah untuk menghentikan penggunaan layanan.
            </p>

            <div className="bold">
                Pembayaran dan Pengembalian Dana (Refund)
            </div>
            <p>
                Diskon yang diberikan oleh Tim Ziqva Labs adalah wewenang penuh bagi kami untuk menentukan angka diskon tersebut. Harga sudah menjadi ketentuan untuk pembayaran
                harga atas lisensi tersebut, dan tidak ada negosiasi, kecuali dengan kuantitas yang dapat disepakati oleh Tim Ziqva Labs.
            </p>
            <p>
                Berhubung kami sudah menyediakan Trial Tanpa Kredit berdurasi 24 jam dengan fitur dan layanan yang sama dengan fitur premium (tanpa adanya batasan), maka untuk pengembalian tidak dapat dilakukan.
            </p>

        </div>
    )
}