import Heading from '@component/Heading'
import MainFooter from '@component/MainFooter'
import {
    Text,
    Spinner
} from '@fluentui/react-components'
import './style.scss'
import HeadOfSection from './HeadOfSection'
import merchandiseIlustration from "@static/ilustrations/merchandise.png"
import getMerchandise from '@utils/main/getMerchandise'
import {useEffect, useState} from 'react'
import ProductItems from './ProductItems'

export default function Merchandise() {
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])

    const getProducts = () => {
        setLoading(true)
        getMerchandise()
        .then(products => {
            setLoading(false)
            setProducts(products)
        })
        .catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getProducts()
    }, [])
    
    return (
        <div className="merchandise" page="merchandise">
            <Heading leftComponent={(
                <div className='mot-heading-left'>
                    <Text className='title'>Merchandise</Text>
                    <Text className='description'>Kami dengan bangga mempersembahkan Merchandise Ziqva Labs, destinasi online eksklusif Anda untuk merchandise berkualitas tinggi yang dirancang dengan cinta untuk penggemar sejati.</Text>
                </div>
            )} mainImage={merchandiseIlustration} />
            <HeadOfSection />
            {loading && <Spinner style={{
                marginTop: 40
               }} /> }
            {!loading && (<ProductItems products={products} />)}
            <MainFooter />
        </div>
    )
}