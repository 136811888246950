function formatRupiah(number) {
    // Ensure the input is a valid number
    if (isNaN(number)) {
        return "Invalid input";
    }

    // Convert the number to a string and remove any non-numeric characters
    const numericString = String(number).replace(/[^\d]/g, "");

    // Format the number as IDR
    const formattedRupiah = "Rp " + numericString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedRupiah;
}

export default formatRupiah