export default function Docs() {
    return (
        <div className="docs">
            <p>
                Kebijakan privasi ini menjelaskan praktek Ziqva Labs mengenai pengumpulan, penggunaan, dan pengungkapan informasi yang kami kumpulkan tentang Anda ketika Anda menggunakan aplikasi berbasis web dan aplikasi desktop.Kami mengambil kewajiban ini mengenai privasi anda dengan serius dan telah membuat setiap usaha untuk menyusun Kebijakan Privasi ini dengan cara yang jelas dan mudah bagi anda untuk dipahami. Dengan mengakses atau menggunakan Layanan, Anda menyetujui Kebijakan Privasi ini, dan Ketentuan Layanan kami.
            </p>
            <div className="v-bold">Pengumpulan dan Penggunaan Informasi</div>
            <div className="bold">Informasi yang Anda berikan kepada kami</div>
            <p>
                Kami mengumpulkan informsi pribadi, seperti nama dan alamat email Anda, saat Anda mendaftar untuk akun di Layanan. Anda juga dapat memberikan informasi opsional seperti nama pengguna, alamat email dan informasi profil opsional
                yang lain untuk dikaitkan dengan akun Anda selanjutnya disebut sebagai anda "Informasi Profil"
            </p>
            <p>
                Kami dapat menggunakan alamat email Anda untuk mengirimkan pemberitahuan Layanan terkait (termasuk pemberitahuan perubahan kebijakan layanan, sebagai pengganti komunikasi melalui surat pos). Kami juga dapat menggunakan alamat email Anda untuk mengirimkan pengumuman
                dan informasi tentang produk atau jasa (termasuk jasa pihak ketiga) lain yang Anda mungkin tertarik (bersama-sama, "Pesan Marketing").
            </p>
            <div className="bold">Konten Anda</div>
            <p>
                Penggunaan Konten atas Layanan akan melibatkan Anda mengupload atau memasukkan berbagai konten ke Layanan. Ziqva Labs tidak punya hak untuk meliha konten anda kecuali untuk validasi keamanan dan perbaikan ketika terdapat permasalahan pada layanan kami atau melakukan pengecekan lebih lanjut. Sekaligus untuk menyelesaikan keluhan dari Anda.
            </p>

            <div className="v-bold">Informasi yang kita Kumpulkan Otomatis</div>
            <div className="bold">Identifikasi Perangkat</div>
            <p>Ketika Anda mengakses Layanan dengan menggunakan perangkat komputer, kami mengumpulkan informasi perangkat tertentu yang terkandung dalam perangkat komputer Anda "perangkat pengenal".
                Pengenal perangkat ini mencakup informasi seperti jenis perangkat yang Anda gunakan, sistem operasi, dan informasi jaringan. Kami dapat mengaitkan pengenal perangkat ini dengan akun Layanan Anda dan akan menggunakan data yang terkait
                dengan pengenal perangkat Anda untuk menyesuaikan Layanan kami untuk perangkat Anda dan untuk menganalisis masalah yang berhubungan dengan perangkat.
            </p>
            <div className="bold">Bagaimana Kami Melindungi Informasi Anda</div>
            <p>
                Keamanan informasi Anda penting bagi kami. Bila Anda memasukkan informasi yang sensitif (seperti password) sebagai bagian dari layanan kami,
                kami mengenkripsi pengiriman informasi yang menggunakan enkripsi standard industri.
                <br/>
                <br/>
                Ziqva Labs menggunakan pengamanan fisik, manajerial, dan teknis yang wajar dan standar industri komersial untuk menjaga integritas dan keamanan informasi Anda.
                Sebagai contoh, kita terus menerus dan teratur backup data Anda untuk membantu mencegah hilangnya data dan bantuan dalam pemulihan data.
            </p>
        </div>
    )
}