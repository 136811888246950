import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  FluentProvider,
  createLightTheme,
} from '@fluentui/react-components'
import './colors.scss'

const ziqvaTheme = {
  10: "#060201",
  20: "#26110D",
  30: "#401815",
  40: "#571D19",
  50: "#6E211E",
  60: "#862523",
  70: "#9F2828",
  80: "#B92B2E",
  90: "#C93C3A",
  100: "#D2544C",
  110: "#DA6A5F",
  120: "#E27E73",
  130: "#E89287",
  140: "#EFA69B",
  150: "#F4B9B0",
  160: "#F8CDC6"
};


const lightTheme = {
  ...createLightTheme(ziqvaTheme),
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FluentProvider theme={lightTheme}>
    <App />
  </FluentProvider>
);
