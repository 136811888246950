import './main-footer.scss'
import AppInformation from './AppInformation'
import InformationLinks from './InformationLinks'
import MediaIcons from './MediaIcons'

export default function MainFooter() {
    return (
        <div className="main-footer">
            <div className="informations">
                <InformationLinks />
                <AppInformation />
            </div>
            <MediaIcons />
            <div className="separator" />
            <div className="copyright">
                Made with ♥ & ☕ | Copyright &copy; {(new Date()).getFullYear()} Ziqva Labs - Pusat dropship terbaik.
            </div>
        </div>
    )
}