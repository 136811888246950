import {useEffect, useState} from 'react'
import config from 'config'
import axios from 'axios'
import getProducts from '@utils/main/getProducts'
import { 
    Spinner,
    Text,
    Button,
    Tooltip,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogBody
 } from '@fluentui/react-components'
 import formatRupiah from '@utils/global/formatRupiah'
 import {
    Warning16Filled
 } from '@fluentui/react-icons'

export default function Packet() {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [products, setProducts] = useState([])
    
    const fetchProducts = () => {
        setLoading(true)
        getProducts()
        .then(products => {
            setProducts(products)
            setLoading(false)
            setError(null)
        })
        .catch(err => {
            setProducts([])
            setError(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <section className="packet" id='service'>
            <Text as='h1' className='section-title'>Produk Unggulan</Text>
            {loading && <Loading />}
            {error !== null && <Error msg={error} />}
            {error === null && (
                <div className="products-container">
                    {products.map((product, index) => <Product key={index} product={product} />)}
                </div>
            )}
        </section>
    )
}

const Product = ({product}) => {
    return (
        <div className="product" name={product.name} >
            <img src={product.image} loading='lazy' alt={product.name} title={product.name} className='icon' />
            <Text className='name'>{product.name}</Text>
            
            <div className="price-container">
                <Text className='price-text'>{formatRupiah(product.price)} / Month</Text>
            </div>
            <div className="flex-cent">
                <a href={`https://appcenter.ziqva.com/dashboard/orderNew?selected_product=${product.product_id}`} target="_blank" rel='noreferrer'>
                    <Button appearance='primary' className='first' size='medium'>Beli</Button>
                </a>
                {product.has_description  && (
                    <Dialog>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance='secondary' size='medium' className='description'>Detail</Button>
                        </DialogTrigger>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>{product.name}</DialogTitle>
                                <DialogContent>{product.description}</DialogContent>
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button appearance='primary'>Tutup</Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                )}
            </div>
            {product.price >= 600000 && (
                <div className="not-recomended-to-buy-it">
                    <Tooltip content='Produk tidak dapat dibeli secara publik'>
                        <Button icon={<Warning16Filled />} appearance='primary' />
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

const Error = ({msg}) => {
    useEffect(() => {
        console.warn('failed for load product:', msg)
    }, [])
    return (
        <div className="error">
            Failed for load the products: {msg}
        </div>
    )
}

const Loading = () => {
    return (
        <div className='loading-container'>
            <Spinner  />
        </div>
    )
}