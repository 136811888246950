import data from '@config/teamsbox'
import {
    Text,
} from '@fluentui/react-components'

export default function TeamsBox() {
    return (
        <div className="teamsbox">
            {data.teams.map((team, index) => (
                <div className='team' key={index}>
                    <img 
                        src={team.image} 
                        alt={`${team.name} - Ziqva Labs Team`} 
                        title={`${team.name} - Ziqva Labs Team`} 
                        className='avatar'
                        loading='lazy'
                    />
                    <Text className='name'>{team.name}</Text>
                    <Text className='position'>{team.position}</Text>
                    <div className="socials">
                        {team.socials.map((social, index) => (
                            <a className='social' key={index} href={social.url} target="_blank" rel='noreferrer'>
                                <img src={social.icon} alt={`${social.name} | ${social.social_name}`} className="icon" title={social.name} />
                            </a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}
