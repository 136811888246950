import storage from 'react-secure-storage'

export default class Cart {
   constructor() {
      this.storageName = 'cart'
      if(storage.getItem(this.storageName) === null) {
         storage.setItem(this.storageName, '[]')
      }
   }

   add(row) {
      const storageBefore = JSON.parse(storage.getItem(this.storageName))
      storage.setItem(this.storageName, JSON.stringify([row, ...storageBefore]))
   }

   removeByIndex(i) {
      let data = JSON.parse(storage.getItem(this.storageName))
      data.splice(i, 1)
      storage.setItem(this.storageName, JSON.stringify(data))
   }

   remove(row) {
      let data = JSON.parse(storage.getItem(this.storageName))
      let tmp = []
      for (let i = 0; i < data.length; i++) {
         if(data[i] === row) { continue }
         tmp.push(data[i])
      }
      storage.setItem(this.storageName, JSON.stringify(tmp))
   }

   getAll() {
      const data = JSON.parse(storage.getItem(this.storageName))
      return data
   }
}