import teamworkIlustration from '@static/ilustrations/teamwork.png'
import Heading from '@component/Heading'
import MainFooter from '@component/MainFooter'
import {
    Text
} from '@fluentui/react-components'
import './style.scss'
import HeadOfSection from './HeadOfSection'
import TeamsBox from './TeamsBox'

export default function MeetOurTeam() {
    return (
        <div className="meet-our-team" page="meet-our-team">
            <Heading leftComponent={(
                <div className='mot-heading-left'>
                    <Text className='title'>Halo, Kami bagian dari Ziqva Labs</Text>
                    <Text className='description'>Kami menyediakan kumpulan paket dropshipping yang telah dirancang sesuai dengan kebutuhan anda.</Text>
                </div>
            )} mainImage={teamworkIlustration} />
            <HeadOfSection />
            <TeamsBox />
            <MainFooter />
        </div>
    )
}