export default function HeadingLeftComponent() {
    return (
        <div className="heading-left-component">
            <h1 className="main">Jadi dropshipper no.1 dengan <b>Ziqva Labs</b></h1>
            <div className="submain">Permudah pekerjaan anda dengan kami</div>
            <div className="privilege">
                <div className="item">Free trial 1 hari tanpa kredit</div>
                <div className="item">Customer support</div>
                <div className="item">Update & Maintenance</div>
                <div className="item">Sistem Pembayaran Otomatis</div>
            </div>
        </div>
    )
}