import './style.scss'
import Header from '@component/Header'

import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom'

// Pages
import MainPage from '@pages/MainPage'
import AboutUs from '@pages/AboutUs'
import MeetOurTeam from '@pages/MeetOurTeam'
import Contact from '@pages/Contact'
import PrivacyPolicy from '@pages/PrivacyPolicy'
import TermsAndConditions from '@pages/TermsAndConditions'
import Merch from '@pages/Merchandise'
import MerchDetail from '@pages/MerchDetail'
import Cart from '@pages/Cart'

export default function App() {
    return (
        <div className="app">
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route exact path='/' element={<MainPage />} />
                    <Route exact path='/about-us' element={<AboutUs />} />
                    <Route exact path='/meet-our-team' element={<MeetOurTeam />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route exact path='/terms-and-conditions' element={<TermsAndConditions />} />
                    <Route exact path='/merch/:merchid' element={<MerchDetail />} />
                    <Route exact path='/merch' element={<Merch />} />
                    <Route exact path='/cart' element={<Cart />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}