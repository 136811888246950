import config from '@config/server'
import axios from 'axios'

export default function getMerchandise() {
   return new Promise((resolve, reject) => {
      const url = `${config.api.base}/productList`
      axios.get(url)
      .then(({data}) => {
         if(data.error) {
            reject(data.msg)
         } else {
            resolve(data.data)
         }
      })
      .catch(err => {
         const errMsg = typeof err === 'string' ? err : err.message
         reject(errMsg)
      }) 
   })
}