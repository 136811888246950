import {
    Text
} from '@fluentui/react-components'


export default function HeadOfSection() {
    return (
        <div className="head-of-section">
            <Text className='title'>
                Temui tim kami yang luar biasa
            </Text>
            <div className='descriptions'>
                <Text className='d'>Ziqva Labs, didukung oleh <div className="bold-red">Kampung Songo</div></Text>
                <Text className='d'>Dengan tim yang luar biasa</Text>
                <Text className='d'>Kami terus berusaha memberikan layanan terbaik untuk Anda.</Text>
            </div>
        </div>
    )
}