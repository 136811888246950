import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button
} from "@fluentui/react-components";

export default function PaymentTermsDialog() {
    return (
        <>

            <Dialog>
                <DialogTrigger disableButtonEnhancement>
                    <div className="payment-terms">
                        <div className="text">Ketentuan Pembayaran</div>
                    </div>
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Ketentuan Pembayaran</DialogTitle>
                        <DialogContent>
                            Pembayaran wajib dibayar lunas diawal. Harga yang ditampilkan merupakan total harga produk dibagi durasi langganan yang anda pilih.
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance='primary'>Tutup</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    )
}