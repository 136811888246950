import {useEffect, useState} from 'react'
import './sidebar.scss'
import DesktopMode from './DesktopMode'
import MobileMode from './MobileMode'
import {useLocation} from 'react-router-dom'

export default function Sidebar({topPosition}) {
    const [sw, setSw] = useState(0) // Screen width
    const handleResize = e => {
        setSw(window.innerWidth)
    }   

    const location = useLocation()

    useEffect(() => {
        if(location.hash != '' && location.hash.includes('#')) {
            const targetElement = document.querySelector(location.hash)
            if(targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest'
                })
            }
        } else {
            window.scrollTo(0, 0)
        }
    }, [location])

    useEffect(() => {
        setSw(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    
    return (
        <div className="sidebar" mobile={sw<900?'1':'0'}>
            {sw>=900  && (
                <DesktopMode topPosition={topPosition} />
            )}
            {sw<900 && (
                <MobileMode topPosition={topPosition} />
            )}
        </div>
    )
}