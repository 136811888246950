import {
    Text,
} from '@fluentui/react-components'
import {
    CheckboxChecked20Filled
} from '@fluentui/react-icons'
import buyDigitalImg from '@static/ilustrations/buy-digital.png'

export default function Superiority() {
    return (
        <div className="superiority">
            <div className="left">
                <Text className='title'>
                    Tools Dropship Berkualitas dan Terlengkap.
                </Text>
                <div className="items">
                    <div className="item">
                        <CheckboxChecked20Filled color='var(--main-color)' />
                        <Text className='text'>Tim Support Handal</Text>
                    </div>
                    <div className="item">
                        <CheckboxChecked20Filled color='var(--main-color)' />
                        <Text className='text'>Mudah Digunakan</Text>
                    </div>
                    <div className="item">
                        <CheckboxChecked20Filled color='var(--main-color)' />
                        <Text className='text'>Instant Update</Text>
                    </div>
                    <div className="item">
                        <CheckboxChecked20Filled color='var(--main-color)' />
                        <Text className='text'>Penghematan Biaya & Waktu</Text>
                    </div>
                    <div className="item">
                        <CheckboxChecked20Filled color='var(--main-color)' />
                        <Text className='text'>Bebas Trial 24 Jam Tanpa Kredit</Text>
                    </div>
                </div>
            </div>
            <div className="right">
                <img src={buyDigitalImg} alt='Buy Ziqva Labs' loading='lazy' className='main-img' />
            </div>
        </div>
    )
}