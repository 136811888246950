import axios from 'axios'
import config from 'config'

export default function getProducts() {
    return new Promise((resolve, reject) => {
        const targetUrl = `${config.api_host}/productList`
        axios.get(targetUrl)
        .then(({data}) => {
            if(data.error) {
                reject(data.reason)
            } else {
                resolve(data)
            }
        })
        .catch(err => {
            reject(err instanceof Error ? err.message : err)
        })
    })
}