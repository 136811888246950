import config from '@config/footer.json'
import {
    Link
} from 'react-router-dom'

export default function InformationLinks() {
    return (
        <div className="information-links">
            {config.information_links.map((information_link, il_index) => (
                <div className="_links" key={il_index}>
                    <div className="title">{information_link.name}</div>
                    <div className="urls">
                        {information_link.urls.map((url, url_index) => (
                            <Link to={url.url} target={url.blank ? '_blank' : ''} className='url' key={url_index}>
                                {url.name}
                            </Link>
                        ))}
                    </div>
                </div>
            ))}    
        </div>
    )
}