import contacts from '@config/contacts'

export default function MediaIcons() {
    return (
        <div className="media-icons">
            {contacts.map((contact, index) => (
                <a href={contact.url} rel='noreferrer' target='_blank' key={index} title={contact.name + ' | ' + contact.urlName}>
                    <img loading='lazy' src={contact.icon} alt={`Ziqva Labs Contact | ${contact.name}`} className="icon" />
                </a>
            ))}
        </div>  
    )
}