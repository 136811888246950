import './index.scss'
import Heading from '@component/Heading'
import HeadingLeftComponent from './HeadingLeftComponent'
import ilustrationImage from '@static/ilustrations/girl-with-laptop.png'
import Footer from '@component/MainFooter'
import Packet from './Packet'
import {
    Text
} from '@fluentui/react-components'
import PaymentTermsDialog from './PaymentTermsDialog'
import Superiority from './Superiority'
import TextTestimonial from './TextTestimonial'
import Join from './Join'

export default function MainPage() {
    return (
        <div className="main-page" page='main-page'>
            <Heading leftComponent={<HeadingLeftComponent />} mainImage={ilustrationImage} />
            <Packet />
            <PaymentTermsDialog />
            <Superiority />
            <TextTestimonial />
            <Join />
            <Footer />
        </div>
    )
}