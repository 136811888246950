import lookingThroughIlustration from '@static/ilustrations/looking-through.png'
import {
    Text
} from '@fluentui/react-components'
import businessWomanIlustration from '@static/ilustrations/business-woman.png'


export default function SimpleAbout() {
    return (
        <div className="simple-about">
            <div className="section-title">Tentang Ziqva Labs</div>
            <div className="items">
                <div className="item">
                    <div className="information">
                        <Text className="title">Perjalan Dimulai dengan Sebuah Misi</Text>
                        <Text className="description">Sejak didirikan pada 2022, Ziqva Labs membawa misi untuk meningkatkan kesadaran jutaan dropshipper diindonesia akan pentingnya memaksimalkan potensi internet melalui sebuah website. Berangkat dari hal ini, kami mulai menghadirkan produk serta layanan web hosting yang tidak hanya memiliki harga bersaing, tapi juga jaminan kualitas terbaik.</Text>
                    </div>
                    <img src={lookingThroughIlustration} alt="Misi Kami" className='ilustration' />
                </div>
                <div className="item">
                    <img src={businessWomanIlustration} alt="Pelanggan Kami" className='ilustration' />
                    <div className="information">
                        <Text className="title">Bantu Jutaan Dropshippere Raih Sukses Online</Text>
                        <Text className="description">
                            Dipercayai pelanggan yang terbesar di berbagai penjuru tanah air, Ziqva Labs berikan dukungan penuh dalam merahin kesuksesan online melalui beragam aspek.
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}
