import icon from '@static/logo.png'
import { Link } from 'react-router-dom'

export default function AppInformation() {
    return (
        <div className="app-information">
            <Link to='/' className="app">
                <img src={icon} alt="Ziqva Labs" title='Ziqva Labs' className='logo' />
                <h1 className='name'>Ziqva Labs</h1>
            </Link>

            <p>
            Ziqva Labs adalah platform yang dikelola dan dikembangkan oleh Kampung Songo, sebuah platform yang menyediakan kumpulan tools untuk memudahkan Anda dalam berbisnis dropshipping. Kami mengerti bahwa berbisnis dropshipping bisa menjadi tantangan yang memerlukan banyak waktu dan usaha, oleh karena itu kami hadir untuk membantu mempermudah proses tersebut.
            </p>

            <p>
                Jl. Gunung Tugu Indah RT.61 RW 13 Basecamp Kampung Songo, Mbersih, Sumbersih, Kec. Donomulyo, Kabupaten Malang, Jawa Timur 65167
            </p>

            {/* {config.contacts.map((contact, index) => (
                <span
                key={index}
                className='contact-link'>{contact.name}: 
                    <a href={contact.url}>{contact.urlName}</a>
                </span>
            ))} */}
        </div>
    )
}