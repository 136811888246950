import './index.scss'
import Heading from '@component/Heading'
import MainFooter from '@component/MainFooter'
import mainIlustration from "@static/ilustrations/terms-and-condition.png"
import {
    Text
} from '@fluentui/react-components'
import Doc from './Doc'

export default function TermsAndConditions() {
    return (
        <div className="terms-and-conditions" page="terms-and-conditions">
            <Heading mainImage={mainIlustration} leftComponent={(
                <div className='tac-heading'>
                    <Text className='text'>Syarat Dan Ketentuan</Text>
                </div>
            )} />
            <Doc />
            <MainFooter />
        </div>  
    )
}