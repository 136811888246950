import axios from 'axios'
import config from '@config/server.json'


export default function getProductDetail(id) {
   return new Promise((resolve, reject) => {
      const url = `${config.api.base}/merchDetail`
      axios.post(url, {
         merchId: id
      })
      .then(({data}) => {
         if(data.error) {
            reject(data.msg)
         } else {
            resolve(data.data)
         }
      })
      .catch(err => {
         const errMsg = typeof err === 'string' ? err : err.message
         reject(errMsg)
      })
   })
}