import './index.scss'
import Heading from '@component/Heading'
import ilustrationImage from '@static/ilustrations/features.png'
import Footer from '@component/MainFooter'
import {
    Text
} from '@fluentui/react-components'
import SimpleAbout from './SimpleAbout'
import TextTestimonial from '@pages/MainPage/TextTestimonial'

export default function AboutUs() {
    return (
        <div className="about-us" page='about-us'>
            <Heading leftComponent={(
                <Text  className='heading-left-text' style={{
                    fontWeight: 600,
                    fontSize: 30,
                    letterSpacing: 2,
                    lineHeight: 1.5
                }}>
                    <div>Always</div>
                    <div>By</div>
                    <div>Your Side</div>
                </Text>
            )} mainImage={ilustrationImage} />
            <SimpleAbout />
            <div className="trusted">
                <Text className='title'>Dipercaya oleh Berbagai Dropshipper di Seluruh Indonesia</Text>
                <Text className='description'>Ratusan pelanggan telah membuktikan sendiri kehebatan layanan Ziqva Labs. Kini giliran Anda!</Text>
                <TextTestimonial />
            </div>
            <Footer />
        </div>
    )
}