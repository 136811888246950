import Heading from '@component/Heading'
import MainFooter from '@component/MainFooter'
import {
  Text,
  Spinner,
  Label,
  makeStyles,
  Radio,
  RadioGroup,
  tokens,
  useId,
  Button,
  Input,
  Body1,
  Checkbox,
  Link,
  Toaster,
  useToastController,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter
} from '@fluentui/react-components'
import './style.scss'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import getProductDetail from '@utils/main/getProductDetail'
import Helmet from 'react-helmet'
import ImageGallery from 'react-image-gallery'
import config from '@config/server.json'
import formatRupiah from '@utils/global/formatRupiah'
import {
  Add16Filled,
  Subtract16Filled,
  Cart16Regular
} from '@fluentui/react-icons'
import ConfirmationDialog from './ConfirmationDialog'
import axios from 'axios'
import Cart from '@utils/global/Cart'

const cart = new Cart()

const useStyles = makeStyles({
  field: {
    display: 'grid',
    gridRowGap: tokens.spacingVerticalS
  }
})

export default function MerchDetail() {
  const navigate = useNavigate()
  const [productId, setProductId] = useState(undefined)
  const [productDetail, setProductDetail] = useState(undefined)
  const [productName, setProductName] = useState('Merch')
  const [selectedVariant, setSelectedVariant] = useState(undefined)
  const [quantity, setQuantity] = useState(1)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [checkboxText, setCheckboxText] = useState('')
  const [useCheck, setUseCheck] = useState(false)
  const [additionalPriceCheck, setAdditionalPriceCheck] = useState(0)
  const [buyerName, setBuyerName] = useState('')
  const [buyerContact, setBuyerContact] = useState('')
  const [orderNote, setOrderNote] = useState('')

  const cartToastId = useId('toast_add_to_cart')
  const {dispatchToast} = useToastController(cartToastId)
  const notifySuccessAddToCart = () => {
    dispatchToast(
      <Toast>
        <ToastTitle>Berhasil</ToastTitle>
        <ToastBody>Item telah berhasil ditambahkan ke keranjang</ToastBody>
        <ToastFooter>
          <Link onClick={gotoCart}>Lihat Keranjang</Link>
        </ToastFooter>
      </Toast>,
      {intent: 'success'}
    )
  }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [dialogShow, setDialogShow] = useState(false)

  const dialogShowToggle = () => setDialogShow(x => !x)

  const handleAddQuantity = () => {
    setQuantity(x => x+1)
  }

  const handleSubstractQuantity = () => {
    if(quantity>1) {
      setQuantity(x => x-1)
    }
  }

  const styles = useStyles()
  const labelId = useId('label')

  const params = useParams()

  useEffect(() => {
    setProductId(params.merchid)
  }, [params])


  useEffect(() => {
    if (typeof productId === 'string') {
      getProductDetail(productId)
        .then(data => setProductDetail(data))
        .catch(err => {
          console.error(err)
        })
    }
  }, [productId])

  useEffect(() => {
    if (typeof productDetail !== 'undefined') {
      setProductName(productDetail.name)
      setUseCheck(productDetail.use_check)
      setCheckboxText(productDetail.check_text)
      setAdditionalPriceCheck(productDetail.additional_price_check)
      setSelectedVariant(productDetail.variants[0])
    }
  }, [productDetail])


  const handleVariantChange = name => {
    const variants = [...productDetail.variants]
    const i = variants.findIndex(x => x.name === name)
    if (i >= 0) {
      setSelectedVariant(variants[i])
    }
  }

  const processOrder = () => {
    setConfirmLoading(true)
    const args = {
      productId: productId,
      productName: productName,
      selectedVariant: selectedVariant,
      quantity: quantity,
      checkboxChecked: checkboxChecked,
      checkboxText: checkboxText,
      useCheck: useCheck,
      additionalPriceCheck: additionalPriceCheck,
      buyerName: buyerName,
      buyerContact: buyerContact,
      orderNote: orderNote,
    }

    const url = `${config.api.base}/processOrder`
    axios.post(url, args)
    .then(({data}) => {
      setConfirmLoading(false)
      if(data.error) {
        window.alert(data.msg)
      } else {
        window.open(data.redirUrl, "_blank")
      }
    })
    .catch(err => {
      console.error(err)
    })
  }

  const gotoCart = () => {
    navigate('/cart')
  }

  const handleAddToCart = () => {
    const args = {
      productId: productId,
      productName: productName,
      selectedVariant: selectedVariant,
      quantity: quantity,
      checkboxChecked: checkboxChecked,
      checkboxText: checkboxText,
      useCheck: useCheck,
      additionalPriceCheck: additionalPriceCheck,
      buyerName: buyerName,
      buyerContact: buyerContact,
      orderNote: orderNote,
    }
    cart.add(args)
    notifySuccessAddToCart()
  }

  return (
    <div className="merch_detail" page="merch_detail">
      <Toaster toasterId={cartToastId} />
      <ConfirmationDialog
        open={dialogShow}
        confirmLoading={confirmLoading}
        onClose={() => dialogShowToggle()}
        onConfirm={processOrder}
        onNote={note => setOrderNote(note)}
        onBuyerName={(buyerName) => setBuyerName(buyerName)}
        onBuyerContact={(contact) => setBuyerContact(contact)}
      />
      <Helmet>
        <title>{productName} - Ziqva Labs</title>
        {productDetail && <meta name='description' content={productDetail.description} />}
        {productDetail && <meta name='keywords' content={productDetail.keywords} />}
      </Helmet>
      <Heading leftComponent={false} mainImage={false} />
      {productDetail === undefined && <Spinner style={{
        marginTop: 40
      }} />}
      {productDetail !== undefined && (
        <>
          <div className="merch">
            <div className="left">
              <ImageGallery
                showPlayButton={false}
                items={productDetail.images.map(x => {
                  x = `${config.api.base}/img/${x}`
                  return {
                    originalAlt: productDetail.name,
                    thumbnailAlt: productDetail.name,
                    original: x,
                    thumbnail: x
                  }
                })} />
            </div>
            <div className="right">
              <Text className='merch_name'>{productDetail.name}</Text>
              <div className="flex-information" style={{ paddingBottom: 30 }}>
                <div className="sold"><div className="val">{productDetail.order_count}</div> Terjual</div>
              </div>
              {selectedVariant && selectedVariant.price && (
                <Text className="price">{formatRupiah(checkboxChecked ? selectedVariant.price + additionalPriceCheck : selectedVariant.price)}</Text>
              )}
              <Label id={labelId} style={{ paddingTop: 20 }}>Varian</Label>
              <RadioGroup aria-labelledby={labelId} value={selectedVariant ? selectedVariant.name : ''} onChange={(e, name) => handleVariantChange(name.value)}>
                {productDetail.variants.map((varian, i) => (
                  <Radio value={varian.name} label={varian.name} />
                ))}
              </RadioGroup>
              {useCheck && (
                <div className="cek">
                  <Checkbox checked={checkboxChecked} onChange={(e, n) => setCheckboxChecked(n.checked)} label={checkboxText} />
                </div>
              )}
              <div className="quantity" style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                <Label htmlFor='input-quantity'>Kuantitas</Label>
                <Input type='number' value={quantity} style={{ maxWidth: 120, marginLeft: 10 }} aria-labelledby='input-quantity' id='input-quantity'
                  contentBefore={<Button
                    appearance='transparent'
                    size='small'
                    icon={<Subtract16Filled />}
                    onClick={handleSubstractQuantity}
                  ></Button>}
                  contentAfter={<Button
                    appearance='transparent'
                    size='small'
                    onClick={handleAddQuantity}
                    icon={<Add16Filled />}
                  ></Button>}
                />
              </div>
              <div className="primary-action">
                <Button
                  appearance='primary'
                  onClick={handleAddToCart}
                >Tambahkan ke Keranjang</Button>
                <Button appearance='secondary' style={{marginLeft: 10}} icon={<Cart16Regular />} onClick={gotoCart}>
                    Keranjang
                </Button>
              </div>
            </div>
          </div>
          <div className="merch">
            <Text className="description">
              {productDetail.description}
            </Text>
          </div>
        </>
      )}
      <MainFooter />
    </div>
  )
}