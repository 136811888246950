import './index.scss'
import {useEffect, useState} from 'react'
import logoRed from '@static/logo.png'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import config from 'config'
import {Helmet} from 'react-helmet'
import {useLocation} from 'react-router-dom'
import locations from '@config/locations.json'

export default function Header() {
    const location = useLocation()
    const [topScroll, setTopScroll] = useState(0)
    const [title, setTitle] = useState(config.site_name)
    const [description, setDescription] = useState(config.description_default)
    
    useEffect(() => {
        const locName = locations.find(x => x[1] === location.pathname+location.hash)
        if (locName === undefined) {
            setTitle('Page Not Found | ' + config.site_name)
            setDescription(config.description_default)
        } else {
            setTitle(`${locName[0]} | ${config.site_name}`)
            setDescription(locName[2])
        }
    }, [location])
    
    const scrollHandler = () => {
        setTopScroll(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler)
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    return (
        <div className="header" top-position={topScroll}>
            <Helmet>
                <title>{title}</title>  
                <meta name="description" content={description} />
            </Helmet>
            <div className="left">
                <Link className="app" to='/'>
                    <img src={logoRed} alt="Ziqva Labs" className="logo" loading='lazy' />
                    <h1 className="name">Ziqva Labs</h1>
                </Link>
            </div>
            <Sidebar topPosition={topScroll} />
        </div>
    )
}