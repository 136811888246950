import { useEffect, useState } from 'react'
import Cart from '@utils/global/Cart'
import parseCart from '@utils/global/parseCart'
import {
   Spinner,
   Text,
   Card,
   CardHeader,
   Button,
   Tooltip
} from '@fluentui/react-components'
import formatRupiah from '@utils/global/formatRupiah'
import {
   Delete16Filled,
   Warning16Filled,
   Receipt20Filled
} from '@fluentui/react-icons'
import ConfirmationDialog from './ConfirmationDialog'
import axios from 'axios'
import config from '@config/server.json'

const cart = new Cart()

let deleteTimeout

export default function KeranjangData() {

   const [dataCart, setDataCart] = useState([])
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(true)
   const [needToBePaid, setNeedToBePaid] = useState(0)
   const [deleteConfirm, setDeleteConfirm] = useState(false)
   const [processLoading, setProcessLoading] = useState(false)
   const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
   const [buyerName, setBuyerName] = useState('')
   const [buyerContact, setBuyerContact] = useState('')
   const [orderNote, setOrderNote] = useState('')
   const [payment, setPayment] = useState('')

   const confirmDialogToggle = () => setConfirmDialogOpen(x => !x)

   useEffect(() => {
      if(confirmDialogOpen && data.length < 1) {
         setConfirmDialogOpen(false)
      }
   }, [confirmDialogOpen, data])

   useEffect(() => {
      if (deleteConfirm) {
         if (deleteTimeout) { clearTimeout(deleteTimeout) }
         deleteTimeout = setTimeout(() => {
            setDeleteConfirm(false)
         }, 5000)
      }
   }, [deleteConfirm])

   useEffect(() => {
      setDataCart(cart.getAll())
   }, [])

   useEffect(() => {
      setLoading(true)
      parseCart(dataCart)
         .then(data => {
            setData(data.carts)
            setNeedToBePaid(data.needsToBePaid)
            setLoading(false)
         })
         .catch(err => {
            console.error(err)
         })
   }, [dataCart])

   const handleDelete = (i) => {
      if (!deleteConfirm) {
         setDeleteConfirm(true)
      } else {
         cart.removeByIndex(i)
         const dataCart = cart.getAll()
         setDataCart(dataCart)
         setDeleteConfirm(false)
      }
   }

   const handleSubmitOrder = () => {
      const note = `${orderNote}\n\n${payment}`.trim()
      setProcessLoading(true)
      const url = `${config.api.base}/makeOrders`
      const args = {
         note,
         buyerName: buyerName,
         buyerContact: buyerContact,
         orders: data
      }
      axios.post(url, args)
      .then(({data}) => {
         if(data.error) {
            console.error(data.msg)
         } else {
            window.open(data.redirUrl, '_blank')
            setConfirmDialogOpen(false)
         }
         setProcessLoading(false)
      })
      .catch(err => {
         const errMsg = typeof err === 'string' ? err : err.message
         console.error(errMsg)
         setProcessLoading(false)
      })
   }

   return (
      <div className="keranjang_data">
         <ConfirmationDialog
            open={confirmDialogOpen}
            onClose={confirmDialogToggle}
            onBuyerName={buyerName => setBuyerName(buyerName)}
            onBuyerContact={buyerContact => setBuyerContact(buyerContact)}
            onNote={orderNote => setOrderNote(orderNote)}
            confirmLoading={processLoading}
            onConfirm={handleSubmitOrder}
            amount={needToBePaid}
            onPayment={payment => setPayment(payment)}
         />
         {loading && (
            <div className="loading_container">
               <Spinner />
            </div>
         )}
         {!loading && (
            <div className="data_container">
               <Button appearance='primary' onClick={() => {
                  window.location.href = '#checkout'
               }}>Pergi ke Pembayaran</Button>
               {data.map((cart, index) => (
                  <Card className='card' size='small' key={index}>
                     <Tooltip content={deleteConfirm ? 'Tekan sekali lagi untuk konfirmasi' : 'Hapus item'}>
                        <Button aria-isc={deleteConfirm ? '1' : '0'} className='delete_btn' appearance='ghost' onClick={() => handleDelete(index)} icon={deleteConfirm ? <Warning16Filled /> : <Delete16Filled />} />
                     </Tooltip>
                     <header className='h'>
                        {cart.images.map((image, index) => (
                           <img width={80} src={`https://merchandise.srv-ziqlabs-1.my.id/img/${image}`} alt={cart.productName} title={cart.productName} key={index} />
                        ))}
                     </header>
                     <CardHeader
                        header={<Text className='card_header__title'>
                           {cart.productName}
                        </Text>}
                        description={<Text className='card_header__pcs'>
                           {cart.quantity} pcs
                        </Text>}
                     />
                     <footer>
                        <Text className='cart_variant__name'>{cart.selectedVariant.name} ({cart.keterangan})</Text>
                        <Text className='cart_footer__price'>{formatRupiah(cart.totalPaidPrice)}</Text>
                     </footer>
                  </Card>
               ))}
            </div>
         )}
         <section id="checkout">
            <Tooltip content='Jumlah yang harus dibayar'>
               <div className="need_to_be_paid">
                  <Receipt20Filled className='icon' />
                  <Text className='amount'>Jumlah yang harus dibayar: <div className="number">
                     {formatRupiah(needToBePaid)}</div></Text>
               </div>
            </Tooltip>
            <Button className='btn_continue' appearance='primary' onClick={confirmDialogToggle}>Lanjutkan pembayaran</Button>
         </section>
      </div>
   )
}