import {
    Text,
    Button
} from '@fluentui/react-components'
import {useEffect, useState} from 'react'
import {
    CheckboxCheckedFilled
} from '@fluentui/react-icons'
import getMember from '@utils/main/getMembers'

export default function Join() {
    const [member, setMember] = useState('*')

    useEffect(() => {
        getMember()
        .then(member => {
            setMember(member.total_members)
        })
        .catch(err => {
            console.warn('Failed for get member at join:main, ', err)
        })
    }, [])
    
    return (
        <div className="join">
            <Text className='main-text'>Segera Bergabung dengan {member} Dropshipper Lainnya</Text>
            <a 
            href="https://appcenter.ziqva.com/auth/up"
            target="_blank">
            <Button size='large' appearance='primary' className='join-btn'>Mulai Sekarang</Button>
            </a>
            <Text className='subtext'>
                <CheckboxCheckedFilled className='icon' color='var(--main-color)' />
                Bebas Trial 24 Jam Tanpa Kredit
            </Text>
        </div>
    )
}