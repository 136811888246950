import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel'
import anggriawanDingXiang from '@static/image/testimonial/1.jpg'
import gunawan from '@static/image/testimonial/2.webp'
import slamet from '@static/image/testimonial/3.jpg'
import { Avatar, Persona } from "@fluentui/react-components";

export default function TextTestimonial() {
    return (
        <div className="text-testimonial">
            <Carousel className='carousell' autoFocus autoPlay emulateTouch showArrows showThumbs stopOnHover autoPlay infiniteLoop>
                {tests.map((test, index) => (
                    <div className="test" key={index}>
                        <div className="quote">"{test.test}"</div>
                        <Persona
                            className='persona'
                            name={test.name}
                            secondaryText={test.as}
                            presence={{ status: "available" }}
                            avatar={{
                                image: {
                                    src: test.avatar
                                }
                            }}
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    )
}


const tests = [
    {
        name: "Anggriawan Dingxiang",
        as: "Internet Marketer",
        test: "Secara tools sudah tidak diragukan lagi, yang paling suka support dan etika developer yang rendah hati... Dikembangkan jadi lebih baik lagi mas, semangat terus untuk teamnya.",
        avatar: anggriawanDingXiang
    },
    {
        name: "Nirmala Sucianty Gunawan",
        as: "Internet Marketer",
        test: "Ziqva adalah aplikasi yang bagus. Saya suka bagaimana ia memiliki antarmuka yang unik dan sangat mudah digunakan, terutama bagi orang-orang yang tidak begitu mengikuti perkembangan teknologi. Ini juga sangat cepat dan kualitas konten adalah kedudukan tertinggi.",
        avatar: gunawan
    },
    {
        name: "Slamet",
        as: "Internet Marketer",
        test: "Ziqva very usefull. Baru kali ini nemu tool sekeren ini. Apalagi tim supportnya enak banget kalau lagi ada masalah. Saya pikir awalnya mahal, pas tau cara kerjanya ini jadi murah harganya.",
        avatar: slamet
    }
]