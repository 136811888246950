import emailIcon from '@static/socials/email.png'
import whatsappIcon from '@static/socials/whatsapp.png'
import youtubeIcon from '@static/socials/youtube.png'
import instagramIcon from '@static/socials/instagram.png'
import telegramIcon from '@static/socials/telegram.png'
import facebookIcon from '@static/socials/facebook.png'


// eslint-disable-next-line
export default [
    {
        "name": "Email",
        "urlName": "cs@ziqva.com",
        "url": "mailto:cs@ziqva.com",
        "icon": emailIcon
    },
    {
        "name": "WhatsApp",
        "urlName": "6285876681770",
        "url": "https://wa.me/6285876681770",
        "icon": whatsappIcon
    },
    {
        "name": "YouTube",
        "urlName": "@ZiqvaLabs",
        "url": "https://youtube.com/@ZiqvaLabs",
        "icon": youtubeIcon
    },
    {
        "name": "Instagram",
        "urlName": "Ziqva Labs",
        "url": "https://www.instagram.com/ziqvalabs/",
        "icon": instagramIcon
    },
    {
        "name": "Telegram",
        "urlName": "Ziqva Labs",
        "url": "https://tttttt.me/ziqvalabs",
        "icon": telegramIcon
    },
    {
        "name": "Facebook",
        "urlName": "Ziqva Labs",
        "url": "https://www.facebook.com/profile.php?id=100093400262518",
        "icon": facebookIcon
    }
]