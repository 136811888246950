import config from '@config/headerNavigation.json'
import { Link, useLocation } from 'react-router-dom'
import {
    Button
} from '@fluentui/react-components'

export default function Desktop({topPosition}) {
    const location = useLocation()
        
    return (
        <div className="desktop-mode" top={topPosition}>
            <div className="pages">
                {config.pages.map((page, index) => (
                    <Link target={page.path.startsWith('http') ? '_blank' : ''} to={page.path} className={`page${location.pathname+location.hash.split('?')[0] === page.path ? ' active' : ''}`} top={topPosition} key={index}>
                        <div className="name">{page.name}</div>
                    </Link>
                ))}
            </div>
            <div className="actions">
                <Link to={config.registerUrl} target={config.registerUrl.startsWith('http') ? '_blank' : ''}>
                    <Button className={`sign-up-btn top-${topPosition}`}
                        appearance='transparent'
                    >Daftar Gratis!</Button>
                </Link>
                <Link to={config.loginUrl} target={config.loginUrl.startsWith('http') ? '_blank' : ''}>
                    <Button className={`sign-in-btn top-${topPosition}`}
                        appearance='transparent'
                    >Masuk</Button>
                </Link>
            </div>
        </div>
    )
}