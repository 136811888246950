import Heading from "@component/Heading"
import MainFooter from "@component/MainFooter"
import './style.scss'
import customerServiceIlustration from '@static/ilustrations/customer-service.png'
import { Text } from '@fluentui/react-components'
import CoreSmallContact from './CoreSmallContact'
import Maps from './Maps'

export default function Contact() {
    return (
        <div className="contact" page="contact">
            <Heading leftComponent={(<div className='heading-left'>
                    <Text className='title'>Bantuan & Kontak</Text>
                    <Text className='subtitle'>Dapatkan bantuan pada hari Senin - Sabtu pukul 8:30 hingga 16:00 WIB</Text>
                </div>)} mainImage={customerServiceIlustration} />
            <CoreSmallContact />
            <Maps />
            <MainFooter />
        </div>
    )
}