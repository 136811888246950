import config from '@config/server.json'
import axios from 'axios'

export default function parseCart(carts) {
   return new Promise((resolve, reject) => {
      const url = `${config.api.base}/calculatePrice`
      axios.post(url, { carts })
         .then(({ data }) => {
            if (data.error) {
               reject(data.msg)
            } else {
               resolve(data.data)
            }
         })
         .catch(err => {
            const errMsg = typeof err === 'string' ? err : err.message
            reject(errMsg)
         })
   })
}