import './index.scss'
import Heading from '@component/Heading'
import ilustrationImage from '@static/ilustrations/shoping.png'
import Footer from '@component/MainFooter'
import {
   Text
} from '@fluentui/react-components'
import KeranjangData from './KeranjangData'

export default function AboutUs() {
   return (
      <div className="about-us" page='about-us'>
         <Heading leftComponent={(
            <Text className='heading-left-text' style={{
               fontWeight: 600,
               fontSize: 30,
               letterSpacing: 2,
               lineHeight: 1.5
            }}>
               Keranjang
            </Text>
         )} mainImage={ilustrationImage} />
            <KeranjangData />
         <Footer />
      </div>
   )
}