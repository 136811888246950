import {
    Text
} from '@fluentui/react-components'

export default function CoreSmallContact() {
    return (
        <div className="core-small-contact">
            <Text className='section-title'>Perlu BANTUAN?</Text>
            <div className="contacts">
                <div className="contact">
                    <div className="name">Email CS (slow respon)</div>
                    <a href="mailto:cs@ziqva.com">cs@ziqva.com</a>
                </div>
                <div className="contact">
                    <div className="name">WhatsApp</div>
                    <a href="https://api.whatsapp.com/send/?phone=6285876681770&text&type=phone_number&app_absent=0">085876681770</a>
                </div>
            </div>
        </div>
    )
}