import './heading.scss'

export default function Heading({leftComponent = false, mainImage}) {
    return (
        <div className="heading">
            {leftComponent !== false && (<div className="left-component-container">{leftComponent}</div>)}
            {mainImage !== false && (
                <img src={mainImage} alt="The 1st Dropshipper" className="main-img" loading='lazy' />
            )}
        </div>
    )
}